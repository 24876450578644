import { eventChannel, END } from 'redux-saga'
import { put, take, call, fork } from "redux-saga/effects";
import axios from 'axios';
import get from 'lodash/get';
import qs from 'query-string';
import { isObject } from "reactstrap/lib/utils";

import { NextServerAPI } from '../../utils';
import actions, { actionTypes } from "../../shell/actions";

function filterPaymentMethods(dataList, source, role) {
  const internalAgentPaymentMethods = ['RECURRING_MOTO', 'AUTODEBIT'];
  const userPaymentMethods = dataList.filter(row => !internalAgentPaymentMethods.includes(row.paymentMethod));

  const additionalPaymentMethod = [{
    paymentMethod: 'SEND_PAYMENT_LINK',
    paymentProvider: 'SEND_PAYMENT_LINK',
    isActive: true,
  }];
  /**
   * for payment-link page
   * payment methods dont need to be checked by user's role
   * because role is always normal user
   */
  if (source === 'payment-link') {
    return userPaymentMethods;
  }

  /**
   * for checkout page,
   * payment methods needs to be checked by user's role:
   * internal-agents, external-agents, and normal user
   */
  if (role === 'internal-agents') {
    return [
      ...dataList.filter(row => internalAgentPaymentMethods.includes(row.paymentMethod)),
      ...additionalPaymentMethod,
    ];
  } else if (role === 'external-agents') {
    return additionalPaymentMethod;
  }

  return userPaymentMethods;
}

export function* getBanks() {
  yield put(actions.loadingBanks());

  try {
    const banks = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: '',
      path: `/api/quotations/banks/`,
    });

    yield put(actions.loadedBanks({ banks }));
  } catch (e) {
    console.log(e);
    yield put(actions.errorLoadingBanks());
  }
}

export function* getPaymentMethods(action) {
  const { source, role } = action.payload;

  yield put(actions.loadingPaymentMethods());

  try {
    const paymentMethods = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: '',
      path: '/api/orders/paymentMethod/',
    });

    yield put(actions.loadedPaymentMethods({
      paymentMethods: {
        data: filterPaymentMethods(paymentMethods.data, source, role),
      },
    }));
  } catch (error) {
    yield put(actions.errorLoadingPaymentMethods());
  }
}

export function* submitLeads(action) {
  const { data } = action.payload;

  yield put(actions.submitingLeads());
  try {
    const leads = yield call(NextServerAPI, {
      method: 'POST',
      path: `/api/user/leads/`,
      data,
    });

    yield put(actions.submittedLeads({ leads }))

    action.callback && action.callback(leads);

  } catch (error) {
    yield put(actions.errorSubmittingLeads());
  }
}

export function* getNavigationItems(action) {
  const { navigationMode } = action.payload;

  yield put(actions.loadingNavigationItems());
  try {
    const navigationItems = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/kronos/navigation/?navigationMode=${navigationMode}`,
    });

    if (action.callback) {
      action.callback(navigationItems.data);
    }

    yield put(actions.loadedNavigationItems({ navigationItems }));
  } catch (error) {
    yield put(actions.errorLoadingNavigationItems());
  }
}

export function* getLocationList(action) {
  const { search } = action.payload;

  yield put(actions.loadingLocationList());

  try {
    const locations = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/osm/?search=${search}`,
    });

    if (action.callback) {
      action.callback(locations);
    }

    yield put(actions.loadedLocationList({ locations }));
  } catch (error) {
    yield put(actions.errorLoadingLocationList());
  }
}

export function* submitFinancialCheckup(action) {
  const { financialData, callback } = action.payload;

  yield put(actions.submittingFinancialCheckup());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: `/api/kronos/financial/checkup/`,
      data: financialData,
    });

    if (isObject(response) && response.data && response.data.id) {
      callback(response.data.id);
    }

    yield put(actions.submittedFinancialCheckup());
  } catch (error) {
    yield put(actions.errorSubmittingFinancialCheckup());
  }
}

export function* updateFinancialCheckup(action) {
  const { id, leadInfo, callback } = action.payload;

  yield put(actions.updatingFinancialCheckup());

  try {
    yield call(NextServerAPI, {
      method: 'PUT',
      path: `/api/kronos/financial/checkup/?id=${id}`,
      data: leadInfo,
    });

    callback();

    yield put(actions.updatedFinancialCheckup());
  } catch (error) {
    yield put(actions.errorUpdatingFinancialCheckup());
  }
}

export function* getLatestArticles(action) {

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: '/api/media/latest-articles/',
    });

    if (response.data.status !== 200) {
      action.callback && action.callback({success: false, response});
    }

    if (response.data.status !== 404) {
      action.callback && action.callback({success: true, response});
    }

  } catch (error) {
    action.callback && action.callback({ success: false });
  }
}

export function* getRelatedArticles(action) {
  const { id } = action.payload;

  yield put(actions.loadingRelatedArticles());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/media/related-posts/?id=${id}`,
    });

    yield put(actions.loadedRelatedArticles({ data: response.data.posts.nodes }));
  } catch (error) {
    yield put(actions.errorLoadingRelatedArticles());
  }
}

export function* getCalculatorArticles(action) {
  const { id } = action.payload;

  yield put(actions.loadingCalculatorArticles());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/media/related-posts/?id=${id}`,
    });

    yield put(actions.loadedCalculatorArticles({ data: response.data.posts.nodes }));
  } catch (error) {
    yield put(actions.errorLoadingCalculatorArticles());
  }
}

function createUploader({ data, token, URL, shouldNotAuthenticate }) {
  let emit;
  const CHAT_UPLOAD_API_URL = '/api/agents/chat/send-message/';
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => { };
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const uploadPromise = axios(URL || CHAT_UPLOAD_API_URL, {
    method: 'POST',
    ...(shouldNotAuthenticate ? {} : {headers: {
      authorization: `Bearer ${token}`,
    }}),
    data,
    onUploadProgress: uploadProgressCb
  });
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan) {
  while (true) {
    const progress = yield take(chan);
    yield put(actions.uploadFilesProgress(progress));
  }
}

function* uploadFiles({ payload, callback }) {
  yield put(actions.uploadFilesStart());
  try {
    const [uploadPromise, chan] = yield call(createUploader, payload);
    yield fork(uploadProgressWatcher, chan);
    const res = yield call(() => uploadPromise);
    callback && callback(res);
    yield put(actions.uploadFilesSuccess());
  } catch (e) {
    yield put(actions.uploadFilesFailed(e));
  }
}

export function* getPaymentLink(action) {
  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: `/api/orders/payment-link-generate/`,
      data: action.payload,
    });

    action.callback && action.callback(response);
  } catch (e) {
    console.error(e);
    action.callback && action.callback(e);
  }
}

export function* getSalesforceToken(action) {
  yield put(actions.loadingSalesforceToken());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: '/api/salesforce/auth',
    });

    if (response.data) {
      const sftoken = response.data.salesforceLogin.accessToken;
      action.callback && action.callback(sftoken);
      yield put(actions.loadedSalesforceToken(sftoken));
    }
  } catch (error) {
    yield put(actions.errorLoadingSalesforceToken());
    action.callback && action.callback(error);
  }
}

export default [
  [actionTypes.GET_BANKS, getBanks],
  [actionTypes.GET_PAYMENT_METHODS, getPaymentMethods],
  [actionTypes.SUBMIT_LEADS, submitLeads],
  [actionTypes.GET_NAVIGATION_ITEMS, getNavigationItems],
  [actionTypes.GET_LOCATION_LIST, getLocationList],
  [actionTypes.SUBMIT_FINANCIAL_CHECKUP, submitFinancialCheckup],
  [actionTypes.UPDATE_FINANCIAL_CHECKUP, updateFinancialCheckup],
  [actionTypes.GET_LATEST_ARTICLES, getLatestArticles],
  [actionTypes.GET_RELATED_ARTICLES, getRelatedArticles],
  [actionTypes.GET_CALCULATOR_ARTICLES, getCalculatorArticles],
  [actionTypes.UPLOAD_FILES, uploadFiles],
  [actionTypes.GET_PAYMENT_LINK, getPaymentLink],
  [actionTypes.GET_SALESFORCE_TOKEN, getSalesforceToken],
];
